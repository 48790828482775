<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    scrollable
    :max-width="$vuetify?.display?.mdAndUp ? '50%' : '100%'"
  >
    <v-card>
      <v-card-title>
        <v-toolbar color="white" dense flat :prominent="prominent">
          <v-row class="ma-2 ml-1" align="center">
            <v-icon v-if="titleIcon" :color="titleIconColor" class="mr-1">{{
              titleIcon
            }}</v-icon>
            <v-col>
              <v-toolbar-title v-if="title">
                {{ title }}
              </v-toolbar-title>
              <v-toolbar-title v-if="subtitle">
                {{ subtitle }}
              </v-toolbar-title>
            </v-col>
          </v-row>
          <v-btn
            color="on-primary"
            icon
            :disabled="isLoading"
            @click="doClose()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <slot name="content" />
      </v-card-text>

      <v-card-actions v-if="showCancel || subActionName || !hideActionButton">
        <v-row class="ma-2">
          <!-- Adjust spacing for mobile -->
          <v-spacer />
          <v-btn
            v-if="showCancel"
            color="grey"
            class="text--on-primary mx-2"
            small
            variant="outlined"
            :disabled="isLoading"
            @click="$emit('close')"
          >
            {{ cancelBtn }}
          </v-btn>
          <v-btn
            v-if="subActionName"
            small
            variant="flat"
            :loading="isLoading"
            :disabled="isLoading || actionDisabled"
            color="green"
            class="text--on-primary"
            @click="doSubAction()"
          >
            {{ subActionName }}
          </v-btn>
          <v-btn
            v-if="!hideActionButton"
            small
            :loading="isLoading"
            :disabled="isLoading || actionDisabled"
            color="blue"
            variant="flat"
            class="text--on-primary"
            @click="doAction()"
            :href="actionLink"
            target="_blank"
          >
            {{ actionName }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { TranslateResult } from "vue-i18n";
import { computed, watch, ref } from "vue";
import i18n from "@/plugins/i18n";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  prominent: {
    type: Boolean,
    default: false,
  },
  hideActionButton: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  actionDisabled: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  subtitle: {
    type: String,
    default: "",
  },
  titleIcon: {
    type: String,
    default: "",
  },
  titleIconColor: {
    type: String,
    default: "",
  },
  actionName: {
    type: String,
    default: "",
  },
  actionLink: {
    type: String,
    default: "",
  },
  subActionName: {
    type: String,
    default: "",
  },
  buttonColor: {
    type: String,
    default: "",
  },
  cancelName: {
    type: String,
    default: "",
  },
  fixedWidth: {
    type: String,
    required: false,
  },
  showCancel: {
    type: String,
    required: false,
  },
});

const dialog = ref(false);
const cancelBtn = computed((): TranslateResult => {
  return props.cancelName
    ? props.cancelName
    : i18n.global.t("common.action.cancel");
});
watch(
  () => props.show,
  (newVal) => {
    onShowChanged(newVal);
  }
);
const emit = defineEmits(["close", "action", "subAction"]);
const doClose = () => {
  emit("close");
};
const doAction = () => {
  emit("action");
};
const doSubAction = () => {
  emit("subAction");
};
const onShowChanged = (newVal: boolean): void => {
  dialog.value = newVal;
};
</script>
