export enum PlatformFeature {
  DASHBOARD = "dashboard",
  PAYMENTS = "payments",
  TERMINALS = "terminals",
  ACCOUNT = "account",
  USERS = "users",
  HELP = "help",
  LOGOUT = "logout",
  CUSTOMERS = "customers",
  PAYMENT_LINKS = "paymentLinks",
  PRODUCTS = "products",
  FORMS = "forms",
  FLEET_MANAGEMENT = "fleetManagement",
}
