import { MenuStore } from "@/types/global";
import { defineStore } from "pinia";

export const useMenuStore = defineStore("menu", {
  state: (): MenuStore => ({
    isVisible: true,
  }),
  actions: {
    toggleIsNavDrawerVisible() {
      this.isVisible = !this.isVisible;
    },
  },
});
