<template>
  <base-dialog
    :show="showDialog"
    :title="$t('accounts.addNewAccount.title')"
    :actionName="$t('common.action.create')"
    @action="createAccount()"
    :actionDisabled="isValid()"
    :max-width="$vuetify.display.mdAndUp ? '30%' : '100%'"
    :isLoading="loading"
    :showCancel="$t('common.action.cancel')"
    buttonColor="blue"
    @close="showDialog = false"
  >
    <template #content>
      <v-text-field
        :label="$t('accounts.addNewAccount.companyName')"
        v-model="name"
        :rules="locationRules"
      />
      <v-select
        v-model="country"
        :items="supportCountries"
        item-title="name"
        item-value="code"
        :rules="selectRules"
        :label="$t('accounts.addNewAccount.country')"
      />
    </template>
  </base-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import BaseDialog from "@/components/Common/BaseDialog.vue";
import { useValidation } from "@/composables/validation";
import { supportCountries } from "@/globals/supportCountries";
import { useAuthStore } from "@/stores/auth";

const { name, selectRules, locationRules } = useValidation();
const country = ref();
const loading = ref(false);
const showDialog = ref(false);

// Methods
const resetForm = () => {
  name.value = "";
  country.value = null;
};

const open = async () => {
  resetForm();
  showDialog.value = true;
};

defineExpose({
  open,
});

const isValid = () => {
  return !(name.value && country.value);
};

const createAccount = async () => {
  loading.value = true;

  await useAuthStore().createAccount({
    country: country.value,
    name: name.value,
    business_type: "company",
  });
};
</script>
