import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { NectarStatus } from "@/enums/status.enum";
export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  const status = useAuthStore().accountStatus;
  if (to.name === "login") {
    next();
  } else if (to.name !== "accountSetup" && status === NectarStatus.NEW) {
    next({ name: "accountSetup" });
    return;
  } else {
    next();
  }
};
