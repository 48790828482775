import App from "./App.vue";
import { DirectiveBinding, createApp } from "vue";
import { registerPlugins } from "@/plugins";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuthStore } from "../src/stores/auth";
import {
  stringDateToStringWithNoTime,
  getTimeFormattedWithAMAndPM,
  stringDateToStringWithDefaultFormat,
  getTimeOnly,
} from "./utils/date.utils";
import { formatMoney, truncateString } from "./services/util.service";
import { hasRoleAccessToRoute } from "./utils/role.utils";
import i18n from "./plugins/i18n";

let app: any = null;

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = createApp(App);
    registerPlugins(app);
    // Custom directives, use ie <div v-date="someDate" />
    app.directive("statusColor", {
      beforeMount: (
        el: HTMLElement & { style: { color: string }; classList: DOMTokenList },
        binding: DirectiveBinding
      ) => {
        const title = binding.value;
        const deactivateTitle = [
          i18n.global.t("common.action.deactivate"),
          i18n.global.t("common.action.delete"),
          i18n.global.t("common.action.deleteUser"),
        ].includes(title);

        if (deactivateTitle) {
          el.style.color = "red";
          el.classList.add("text-red", "dotted-line-above");
        } else if (title === i18n.global.t("common.action.activate")) {
          el.style.color = "green";
          el.classList.add("text-green");
        }
      },
      updated: (
        el: HTMLElement & { style: { color: string }; classList: DOMTokenList },
        binding: DirectiveBinding
      ) => {
        const title = binding.value;
        const deactivateTitle = [
          i18n.global.t("common.action.deactivate"),
          i18n.global.t("common.action.delete"),
          i18n.global.t("common.action.deleteUser"),
        ].includes(title);

        if (deactivateTitle) {
          el.style.color = "red";
          el.classList.add("text-red", "dotted-line-above");
        } else if (title === i18n.global.t("common.action.activate")) {
          el.style.color = "green";
          el.classList.add("text-green");
        }
      },
    });
    app.directive("date", {
      beforeMount: (el: { innerText: string }, binding: { value: number }) => {
        const formattedDate = stringDateToStringWithNoTime(binding.value);
        el.innerText = formattedDate;
      },
    });
    app.directive("dateWithTime", {
      beforeMount: (el: { innerText: string }, binding: { value: number }) => {
        const formattedDate = getTimeFormattedWithAMAndPM(binding.value);
        el.innerText = formattedDate;
      },
    });

    app.directive("timeOnly", {
      beforeMount: (el: { innerText: string }, binding: { value: number }) => {
        const formattedDate = getTimeOnly(binding.value);
        el.innerText = formattedDate;
      },
    });

    app.directive("dateOnly", {
      beforeMount: (el: { innerText: string }, binding: { value: number }) => {
        const formattedDate = stringDateToStringWithDefaultFormat(
          binding.value
        );
        el.innerText = formattedDate;
      },
    });

    app.directive("money", {
      beforeMount: (
        el: { style: { color: any }; innerText: string },
        binding: { value: any }
      ) => {
        el.innerText = formatMoney(binding.value);
      },
      updated: (
        el: { style: { color: any }; innerText: string },
        binding: { value: any }
      ) => {
        el.innerText = formatMoney(binding.value);
      },
    });

    app.directive("dash", {
      beforeMount: (el: { innerText: string }, binding: { value: any }) => {
        el.innerText = binding.value || "-";
      },
    });

    app.directive("hasRoleAccessToRoute", {
      beforeMount: (
        el: HTMLElement,
        binding: { value: { pathName: string } }
      ) => {
        if (!hasRoleAccessToRoute(binding.value.pathName)) {
          el.hidden = true;
        }
      },
    });

    app.directive("truncate", {
      beforeMount: (el: { innerText: any }, binding: { value: [any, any] }) => {
        const [text, length] = binding.value;
        el.innerText = truncateString(text, length);
      },
      updated: (el: { innerText: string }, binding: { value: [any, any] }) => {
        const [text, length] = binding.value;
        el.innerText = truncateString(text, length);
      },
    });

    app.directive("click-outside", {
      beforeMount: function (el: any, binding: any) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;

        document.addEventListener("click", ourClickEventHandler);
      },
      updated: function (el: any) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      },
    });

    app.mount("#app");

    if (user) {
      useAuthStore().setUserId(user.uid);
      useAuthStore().afterLogin();
    }
  }
});
