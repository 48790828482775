import {defineStore} from "pinia";
import axios from "@/plugins/axios";
import {useSnackBarStore} from "@/stores/snackbar";

export const useConfluenceStore = defineStore("confluence", {
  actions: {
    async getResource(id: string): Promise<string> {
      try {
        const response = await axios.get(`/confluence/${id}`);
        return response.data.value;
      } catch (error: any) {
        useSnackBarStore().toastError({
          message: error.data.error
        })
        return "";
      }
    }
  }
})
