import moment from "moment-timezone";

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_DATE_FORMAT_WITH_TIME = "MM/DD/YYYY hh:mm:ss";
export const STRING_DATE_FORMAT_WITH_TIME = "MMM D YYYY hh:mm";
export const STRING_DATE_FORMAT_FOR_CALENDAR = "dddd, MMMM Do YYYY"; //e.g. Wednesday, November 30th 2022
export const STRING_DATE_FORMAT = "MMMM DD, YYYY"; //e.g. November 30, 2022

export const calculateAge = (aDate: string): number => {
  return moment().diff(moment(aDate, "YYYYMMDD"), "years");
};

export const getFancyLocalTimeZone = (): string => {
  return moment.tz(moment.tz.guess()).zoneAbbr();
};

export const stringDateToStringWithDefaultFormat = (aDate: number): string => {
  return moment.unix(aDate).format(DEFAULT_DATE_FORMAT);
};

export const stringDateToStringWithNoTime = (aDate: number): string => {
  return moment.unix(aDate).format(STRING_DATE_FORMAT);
};

export const stringDateToStringWithTimeFormat = (aDate: number): string => {
  if (aDate) {
    return moment.unix(aDate).format(STRING_DATE_FORMAT_WITH_TIME);
  } else {
    return "-";
  }
};
export const getTimeFormatted = (aDate: any): string => {
  return moment(aDate).endOf("hours").format(DEFAULT_DATE_FORMAT_WITH_TIME);
};

export const getTimeFormattedWithAMAndPM = (aDate: any): string => {
  if (aDate) {
    return moment.unix(aDate).format("MMM Do YYYY, h:mm A"); // adjust this format as needed
  } else {
    return "-";
  }
};

export const stringDateTimeFormat = (aDate: string): string => {
  if (aDate) {
    return moment(aDate).format(STRING_DATE_FORMAT_FOR_CALENDAR);
  } else {
    return "-";
  }
};

export const getDateAtFirstDateOfLastMonth = (): string => {
  return moment()
    .subtract(1, "month")
    .startOf("month")
    .format(DEFAULT_DATE_FORMAT);
};

export const getDateAtEndDateOfLastMonth = (): string => {
  return moment()
    .subtract(1, "month")
    .endOf("month")
    .format(DEFAULT_DATE_FORMAT);
};

export const getDateAtFirstDateOfMonth = (aDate: string): string => {
  return moment(aDate).startOf("month").format(DEFAULT_DATE_FORMAT);
};

export const getDateAtEndDateOfMonth = (aDate: string): string => {
  return moment(aDate).endOf("month").format(DEFAULT_DATE_FORMAT);
};

export const getDateAtFirstOfCurrentMonth = (): string => {
  return getDateAtFirstDateOfMonth(getToday());
};

export const getDateAtEndOfCurrentMonth = (): string => {
  return getDateAtEndDateOfMonth(getToday());
};

export const getDateAtFirstDateOfLastYear = (): string => {
  return moment()
    .set("month", 0)
    .subtract(1, "year")
    .startOf("month")
    .format(DEFAULT_DATE_FORMAT);
};

export const getStartCurrentYearDate = (): string => {
  return moment().set("month", 0).startOf("month").format(DEFAULT_DATE_FORMAT);
};

export const getDateAtEndDateOfLastYear = (): string => {
  return moment()
    .month(11)
    .subtract(1, "year")
    .endOf("month")
    .format(DEFAULT_DATE_FORMAT);
};

export const getTodayAsTimestamp = (): number => {
  return moment().unix();
};

export const getToday = (): string => {
  return moment().format(DEFAULT_DATE_FORMAT);
};

export const getYesterDay = (): string => {
  return moment().subtract(1, "day").format(DEFAULT_DATE_FORMAT);
};

export const getAYearFromToday = (): string => {
  return moment().subtract(1, "year").format(DEFAULT_DATE_FORMAT);
};

export const getDateWithLessDaysFromToday = (
  daysToSubtract: number
): string => {
  return moment().subtract(daysToSubtract, "day").format(DEFAULT_DATE_FORMAT);
};

export const stringToTimestamp = (aDate: string, tz: string): string => {
  return moment(aDate).tz(tz).format("X");
};

export const getLocalTz = (): string => {
  return moment.tz.guess();
};

export const setStartTime = (aDate: string): string => {
  return moment(aDate).startOf("hours").format(DEFAULT_DATE_FORMAT_WITH_TIME);
};

export const getFancyTimeForCalendar = (aDate: string): string => {
  return aDate ? moment(aDate).format(STRING_DATE_FORMAT_FOR_CALENDAR) : "";
};

export const isInOneYearRange = (start: number, end: number): boolean => {
  return !!(end - start <= 31536000);
};

export const getUnixTime = (formattedDate: string): number => {
  const date = new Date(formattedDate);
  return Math.floor(date.getTime() / 1000);
};

export const getTimeOnly = (aDate: any): string => {
  const timestamp = moment.unix(aDate);
  return timestamp.isValid() ? timestamp.format("hh:mm A") : "-";
};
