import { AppConfig } from "@/types/config";

let config: AppConfig | null = null;
const configs: any = import.meta.glob("./*", {
  eager: true,
});
let mode = import.meta.env.MODE; // Set Vite mode as default

// Take dev-cloud stage/qa config
if (import.meta.env.PROD && import.meta.env.VITE_PROJECT_ID.includes("dev")) {
  mode = "qa";
}

if (import.meta.env.PROD && import.meta.env.VITE_PROJECT_ID.includes("stage")) {
  mode = "stage";
}
if (import.meta.env.PROD && import.meta.env.VITE_PROJECT_ID.includes("demos")) {
  mode = "demos";
}
config = configs[`./${mode}.config.json`];

export default config;
