/* eslint-disable no-param-reassign */

import { AxiosResponse } from "axios";
import axios, { AxiosError } from "axios";
import { getAuth } from "firebase/auth";
import config from "@/configs";

//import store from "@/store";

const FIREBASE_API_FUNCTIONS = axios.create({
  baseURL: config?.apis.base,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

const requestInterceptor = {
  success: (config: any) => {
    const auth = getAuth();
    const { currentUser } = auth;
    if (currentUser) {
      return currentUser
        .getIdToken()
        .then((token) => {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
          return config;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    return config;
  },
  error: (error: AxiosError) => {
    return Promise.reject(error);
  },
};

const responseInterceptor = {
  success: (response: AxiosResponse) => {
    return response;
  },
  error: (error: AxiosError) => {
    // In all cases we want the "response" object here
    return Promise.reject(error.response);
  },
};

FIREBASE_API_FUNCTIONS.interceptors.request.use(
  requestInterceptor.success,
  requestInterceptor.error
);
FIREBASE_API_FUNCTIONS.interceptors.response.use(
  responseInterceptor.success,
  responseInterceptor.error
);

export default FIREBASE_API_FUNCTIONS;
