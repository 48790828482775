import { ref } from "vue";
import i18n from "../plugins/i18n";

export function useValidation() {
  const checkbox = ref(false);
  const password = ref("");
  const confirmPassword = ref("");
  const email = ref("");
  const name = ref("");
  const show = ref(false);
  const showConfirmPassword = ref(false);
  const selected = ref("");
  const company = ref("");
  const isFormValid = ref(false);
  const phone = ref("");

  const emailFormatRule = ref(
    (v: any) =>
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || i18n.global.t("errors.email")
  );
  const emailRules = ref([
    (v: any) => !!v || i18n.global.t("errors.fieldRequired"),
    (v: string) => emailFormatRule.value(v),
  ]);
  const nameRules = ref([
    (v: any) => !!v || i18n.global.t("errors.fieldRequired"),
    (v: string | any[]) =>
      (v && v.length <= 50) || i18n.global.t("errors.maxCharLimit", [50]),
    (v: string) => /^[a-zA-Z\s]*$/.test(v) || i18n.global.t("errors.name"),
  ]);
  const passwordRules = ref([
    (v: any) => !!v || i18n.global.t("errors.fieldRequired"),
    (v: string | any[]) =>
      (v && v.length >= 8) || i18n.global.t("errors.password"),
  ]);
  const confirmPasswordRules = ref([
    (v: any) => !!v || i18n.global.t("errors.fieldRequired"),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (v: string) =>
      confirmPassword.value === password.value ||
      i18n.global.t("errors.matchingPasswords"),
  ]);
  const checkboxRules = ref([(v: any) => !!v || i18n.global.t("errors.agree")]);
  const selectRules = ref([
    (v: any) => !!v || i18n.global.t("errors.fieldRequired"),
  ]);
  const phoneRules = ref([
    (v: any) => !!v || i18n.global.t("errors.fieldRequired"),
    (v: any) =>
      /^\d+$/.test(v) || i18n.global.t("errors.phoneNumbersOnly") || true,
  ]);
  const address1 = ref("");
  const city = ref("");
  const state = ref("");
  const zip = ref("");
  const country = ref("");

  const locationRules = ref([
    (v: any) => !!v || i18n.global.t("errors.required"),
  ]);

  const paymentRules = ref([
    (v: any) => !!v || i18n.global.t("errors.required"),
    (v: any) =>
      (!isNaN(parseFloat(v)) && isFinite(v)) || i18n.global.t("errors.number"),
    (v: any) => v >= 0.5 || i18n.global.t("errors.minValue", [0.5]),
    (v: any) => v <= 999999.99 || i18n.global.t("errors.maxValue", [999999.99]),
  ]);

  const amountFilterRules = ref([
    (v: any) =>
      (!isNaN(parseFloat(v)) && isFinite(v)) || i18n.global.t("errors.number"),
    (v: any) => v >= 0.5 || i18n.global.t("errors.minValue"),
    (v: any) => v <= 999999.99 || i18n.global.t("errors.maxValue"),
  ]);

  const maxRule = ref(
    (v: any, maxVal: number) =>
      v <= maxVal || i18n.global.t("errors.maxValue", [maxVal])
  );

  const last4FilterRules = ref([
    (v: any) =>
      (!isNaN(parseFloat(v)) && isFinite(v) && v.match(/^\d{4}$/)) ||
      i18n.global.t("errors.last4"),
  ]);

  const numberRangeRule = ref(
    (v: any) =>
      (!isNaN(parseFloat(v)) && isFinite(v) && v >= 1 && v <= 99) ||
      i18n.global.t("errors.numberRange", [1, 99])
  );

  const urlFormatRule = ref(
    (v: any) =>
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(v) ||
      i18n.global.t("errors.url")
  );
  const urlRules = ref([
    (v: string) => !!v || i18n.global.t("errors.fieldRequired"),
    (v: string) => urlFormatRule.value(v),
  ]);

  const paymentLinkUrlRules = ref((v: any) => {
    if (!v) {
      return i18n.global.t("errors.fieldRequired");
    } else if (!v.startsWith("https://")) {
      return i18n.global.t("errors.httpsRequired");
    } else {
      return true;
    }
  });

  const productQuantityRules = ref((v: number) => {
    if (v >= 1) {
      return true;
    } else {
      return i18n.global.t("errors.quantity");
    }
  });

  const customFieldCharLimit = ref((v: number) => {
    if (v) {
      return true;
    } else {
      return i18n.global.t("errors.characterLimit");
    }
  });

  const paymentLinkNameRules = ref((v: string) => {
    if (v) {
      return true;
    } else {
      return i18n.global.t("errors.nameRequired");
    }
  });

  const paymentLinkconfirmationPageRules = ref((v: string) => {
    if (v) {
      return true;
    } else {
      return i18n.global.t("errors.provideConfirmationPageUrl");
    }
  });

  const quantityRules = ref((v: number) => {
    if (v >= 1) {
      return true;
    } else {
      return i18n.global.t("errors.quantity");
    }
  });

  return {
    checkbox,
    password,
    confirmPassword,
    email,
    name,
    show,
    showConfirmPassword,
    selected,
    company,
    isFormValid,
    emailFormatRule,
    emailRules,
    nameRules,
    passwordRules,
    confirmPasswordRules,
    checkboxRules,
    selectRules,
    address1,
    city,
    state,
    zip,
    country,
    locationRules,
    paymentRules,
    amountFilterRules,
    maxRule,
    last4FilterRules,
    numberRangeRule,
    phone,
    phoneRules,
    urlRules,
    productQuantityRules,
    customFieldCharLimit,
    paymentLinkNameRules,
    paymentLinkconfirmationPageRules,
    quantityRules,
    paymentLinkUrlRules,
  };
}
