<template>
  <v-select
    v-model="selectedAccount"
    :items="accounts"
    item-title="name"
    item-value="id"
    filled
    hide-details
    no-data-text=""
    @update:model-value="onChangeSession"
    return-object
  >
    <!-- selected account -->
    <template v-slot:prepend-item>
      <div class="sticky-item">
        <v-list-item class="my-2">
          <template v-slot:prepend>
            <v-avatar
              size="30"
              variant="elevated"
              class="primary--text v-sheet--outlined"
              color="background"
            >
              <v-img
                v-if="selectedAccount.logoURL"
                :src="selectedAccount.logoURL"
                alt="logo"
              />
              <div v-else>
                {{ getInitials(selectedAccount.name) }}
              </div>
            </v-avatar>
          </template>
          <v-list-item-title>{{ selectedAccount.name }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("navBar.currentAccount") }}
          </v-list-item-subtitle>
        </v-list-item>
      </div>
    </template>

    <!-- available accounts -->
    <template #item="{ item, props }">
      <v-list-item v-bind="props">
        <template #title>
          <div class="d-flex align-center flex-grow-1 my-2">
            <v-avatar
              variant="elevated"
              class="primary--text v-sheet--outlined mb-1"
              color="background"
              size="30"
            >
              <v-img
                v-if="item.raw.logoURL"
                :src="item.raw.logoURL"
                alt="logo"
              />
              <div v-else>
                {{ getInitials(item.title) }}
              </div>
            </v-avatar>
            <v-list-item-title class="ml-4">
              {{ item.title }}
            </v-list-item-title>
          </div>
        </template>
      </v-list-item>
    </template>

    <!-- add new account  -->
    <template v-slot:append-item>
      <div class="sticky-footer">
        <v-divider />
        <v-btn
          variant="plain"
          size="large"
          class="text-none text-center"
          prepend-icon="mdi-plus-circle"
          @click="onNewAccount"
        >
          {{ $t("accounts.newAccount") }}
        </v-btn>
      </div>
    </template>
  </v-select>
  <add-account-dialog ref="accountDialog" />
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";
import { useAccountStore } from "@/stores/account";
import { computed, onMounted, ref } from "vue";
import AddAccountDialog from "@/components/Account/AddAccountDialog.vue";
import { getInitials } from "@/utils/initials.utils";
const selectedAccount = ref({ name: "", id: "", logoURL: "" });
const accountDialog = ref<null | { open: () => null }>(null);
const authStore = useAuthStore();

const emit = defineEmits(["updateInitials"]);

const userAccounts = computed(() => authStore.getUserAccounts);

// This will now include all accounts except the selected one
const accounts = computed(() => {
  return Object.entries(userAccounts.value)
    .map(([key, val]) => ({
      name: val.name,
      id: key,
      logoURL: val.logoURL,
    }))
    .filter((account) => account.id !== selectedAccount.value.id);
});

const onChangeSession = () => {
  useAuthStore().changeSession({ accountId: selectedAccount.value.id });
};

const onNewAccount = () => {
  accountDialog.value?.open();
};

onMounted(() => {
  const accountStore = useAccountStore();
  const currentAccount = accountStore.account;

  const matchingAccount = accounts.value.find(
    (account) => account.id === currentAccount?.id
  );

  if (matchingAccount) {
    selectedAccount.value = matchingAccount;
  } else {
    // Fallback if no matching account is found
    selectedAccount.value = {
      name:
        (currentAccount as any)?.businessName ||
        currentAccount?.business_profile?.name ||
        currentAccount?.email ||
        currentAccount?.id,
      id: currentAccount?.id || "",
      logoURL: currentAccount?.logoURL || "",
    };
  }
  emit("updateInitials", getInitials(selectedAccount.value.name));
});
</script>
<style scoped>
.sticky-item {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}
.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: white;
}
</style>
