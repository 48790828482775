import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage"; // Import for Firebase Storage

const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

// Initialize Firebase
initializeApp(firebaseConfig);
if (import.meta.env.DEV && import.meta.env.MODE === "development") {
  connectAuthEmulator(getAuth(), import.meta.env.VITE_AUTH_URL);
  connectFirestoreEmulator(db, "localhost", 5002);
  connectStorageEmulator(storage, "localhost", 5005);
}
export { db, storage };
