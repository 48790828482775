<template>
  <div class="container">
    <div class="bee">
      <div class="body">
        <div class="line" />
      </div>
      <div>
        <div class="wing-right" />
        <div class="wing-left" />
      </div>
      <div class="path">
        <div class="pollen" v-for="n in 12" :key="n" />
      </div>
    </div>
  </div>
</template>

<style>
html,
body {
  width: 100%;
  height: 100%;
}
.container {
  position: relative;
  display: flex;
  background: #fffcd9;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.bee {
  position: relative;
  margin: auto;
  align-self: center;
  top: -100px;
  width: 50px;
  height: 50px;
  -webkit-animation: to-fly 0.4s infinite;
  animation: to-fly 0.4s infinite;
}
.body {
  position: relative;
  width: 45px;
  height: 25px;
  border: 4px solid #fbc02d;
  background: #ffeb3b;
  border-radius: 20px;
  perspective: 2500px;
  z-index: 99;
}
.body:before,
.body:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.body:before {
  right: 100%;
  top: 3px;
  border-top: 6px solid transparent;
  border-right: 13px solid #fbc02d;
  border-bottom: 6px solid transparent;
}
.body:after {
  right: 99%;
  top: 6px;
  border-top: 3px solid transparent;
  border-right: 6px solid #ffeb3b;
  border-bottom: 3px solid transparent;
}
.body .line {
  position: relative;
  left: 9px;
  height: 18px;
  width: 17px;
  background: #fbc02d;
}
.wing-right,
.wing-left {
  position: absolute;
  top: -17px;
  left: 12px;
  width: 23px;
  height: 23px;
  background: #ffeb3b;
  border: 4px solid #fbc02d;
  border-radius: 100%;
}
.wing-right {
  left: 15px;
  transform: skew(-20deg);
  -webkit-animation: wing-beat-right 0.25s infinite;
  animation: wing-beat-right 0.25s infinite;
  transform-origin: bottom;
  z-index: 9;
}
.wing-left {
  transform: skew(20deg);
  -webkit-animation: wing-beat-left 0.25s infinite;
  animation: wing-beat-left 0.25s infinite;
  transform-origin: bottom;
  z-index: 999;
}
.path {
  display: inline-block;
  position: relative;
  top: -24px;
  left: -90px;
  width: 140px;
  -webkit-animation: pollen 0.14s infinite;
  animation: pollen 0.14s infinite;
}
.pollen {
  display: inline-block;
  margin: 2px;
  width: 3px;
  height: 3px;
  background: #fbc02d;
  border-radius: 100%;
}
@-webkit-keyframes to-fly {
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes to-fly {
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes wing-beat-right {
  50% {
    transform: rotateX(60deg) skew(-20deg) rotateZ(25deg);
  }
  100% {
    transform: rotateX(0) skew(-20deg);
  }
}
@keyframes wing-beat-right {
  50% {
    transform: rotateX(60deg) skew(-20deg) rotateZ(25deg);
  }
  100% {
    transform: rotateX(0) skew(-20deg);
  }
}
@-webkit-keyframes wing-beat-left {
  50% {
    transform: rotateX(-65deg) skew(20deg) rotateZ(-10deg);
  }
  100% {
    transform: rotateX(0) skew(20deg);
  }
}
@keyframes wing-beat-left {
  50% {
    transform: rotateX(-65deg) skew(20deg) rotateZ(-10deg);
  }
  100% {
    transform: rotateX(0) skew(20deg);
  }
}
@-webkit-keyframes pollen {
  0% {
    left: -90px;
  }
  100% {
    left: -95px;
  }
}
@keyframes pollen {
  0% {
    left: -90px;
  }
  100% {
    left: -95px;
  }
}
</style>
