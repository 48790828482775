import { getAuth } from "firebase/auth";
import {
  NavigationGuardNext,
  RouteLocation,
  RouteLocationNormalized,
} from "vue-router";

export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  const requiresAuth = to.matched.some(
    (record: any) => record.meta.requiresAuth
  );
  const redirectRecord = to.matched.find(
    (record: any) => record.meta.redirectAuth
  );
  const { meta } = redirectRecord || {};
  const auth = getAuth();
  const { currentUser } = auth;
  if (currentUser && redirectRecord) {
    if (to.query.lang) {
      localStorage.setItem("lang", to.query.lang as string);
    }
    next({ name: meta?.redirectAuth } as RouteLocation);
  } else if (requiresAuth && !currentUser) {
    next({ name: "login" });
  } else {
    next();
  }
};
