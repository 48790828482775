import { AccountStore } from "@/types/account";
import axios from "@/plugins/axios";
import { defineStore } from "pinia";
import Stripe from "stripe";
import { useSnackBarStore } from "@/stores/snackbar";
import config from "@/configs";
import { AxiosResponse } from "axios";
import { NectarStatus, AccountPlan } from "@/enums/status.enum";
import { useAuthStore } from "./auth";

export const useAccountStore = defineStore("account", {
  state: (): AccountStore => ({
    account: null,
    loading: false,
    linkLoading: false,
    completeOnboardingLinkLoading: false,
    subscriptionCancelLinkLoading: false,
    subscriptionUpdateLinkLoading: false,
    checkoutSessionLinkLoading: false,
    customerPortalLinkLoading: false,
    planLoading: false,
    accountUsageLoading: false,
    productLoading: false,
    contactInfoLoading: false,
    invoiceLoading: false,
    subscription: {
      id: "",
      cancelAtPeriodEnd: false,
      periodEnd: 0,
    },
    plan: {
      name: "",
      billingCycle: "",
      amount: 0,
    },
    products: [
      {
        id: "",
        name: "",
        description: "",
        price: {
          recurring: {
            interval: "string",
          },
          unit_amount: 0,
        },
      },
    ],
    paymentMethod: {
      brand: "",
      last4: "",
    },
    contactInfo: {
      name: "",
      email: "",
      phone: "",
      address: "",
    },
    upcomingInvoices: [
      {
        id: "",
        amount_due: 0,
        description: "",
        period_end: 0,
        status: "",
      },
    ],
    paidInvoices: [
      {
        id: "",
        amount_due: 0,
        description: "",
        period_end: 0,
        status: "",
      },
    ],
  }),
  getters: {
    isLoading: (state) => state.loading,
    isLinkLoading: (state) => state.linkLoading,
    isCompleteOnboardingLinkLoading: (state) =>
      state.completeOnboardingLinkLoading,
    isPlanLoading: (state) => state.planLoading,
    isAccountUsageLoading: (state) => state.accountUsageLoading,
    isProductLoading: (state) => state.productLoading,
    isContactInfoLoading: (state) => state.contactInfoLoading,
    isInvoiceLoading: (state) => state.invoiceLoading,
    getAccountInitials(): string {
      const getInitials = (name: string) => {
        const parts = name.split(" ") ?? [];
        let initials;

        if (parts.length > 1) {
          const first = (parts.shift() ?? "").charAt(0);
          const last = (parts.pop() ?? "").charAt(0);
          initials = `${first}${last}`.trim();
        } else {
          initials = name.substring(0, 2);
        }

        return initials.toUpperCase();
      };

      return getInitials(
        this.account?.business_profile?.name ||
          this.account?.email ||
          this.account?.id ||
          "--"
      );
    },
    getAccountSelected(): boolean {
      return !!this.account;
    },
    getDefaultCurrency(): string {
      return this.account?.default_currency || "CAD";
    },
    getAccountId(): any {
      return this.account?.id;
    },
  },
  actions: {
    // We clear this way to use in Account page, we don't want to clear the current account
    clearStore(): void {
      this.plan = {
        name: "",
        billingCycle: "",
        amount: 0,
      };
      this.products = [
        {
          id: "",
          name: "",
          description: "",
          price: {
            recurring: {
              interval: "string",
            },
            unit_amount: 0,
          },
        },
      ];
      this.paymentMethod = {
        brand: "",
        last4: "",
      };
      this.contactInfo = {
        name: "",
        email: "",
        phone: "",
        address: "",
      };
      this.upcomingInvoices = [
        {
          id: "",
          amount_due: 0,
          description: "",
          period_end: 0,
          status: "",
        },
      ];
      this.paidInvoices = [
        {
          id: "",
          amount_due: 0,
          description: "",
          period_end: 0,
          status: "",
        },
      ];
    },

    setCurrentAccount(
      newAccount:
        | (Stripe.Account & { logoURL: string; nectarStatus: string })
        | null
    ): void {
      this.account = newAccount;
    },

    async fetchPlanInfo(): Promise<void> {
      try {
        this.planLoading = true;
        const response = await axios.get("/account/plan");
        const plan: any = response.data.plan;
        this.plan = plan;
        const subscription: any = response.data.subscription;
        this.subscription = subscription;
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.planLoading = false;
      }
    },

    // unused but might need in future(?)
    async getAccountPlan(): Promise<void> {
      try {
        this.planLoading = true;
        const response = await axios.get("/account/plan");
        return response.data.plan;
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.planLoading = false;
      }
    },

    // unused but might need in future(?)
    async getAccountSubscription(): Promise<void> {
      try {
        this.planLoading = true;
        const response = await axios.get("/account/plan");
        return response.data.subscription;
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.planLoading = false;
      }
    },

    async fetchProductOptions(): Promise<void> {
      try {
        this.productLoading = true;
        const response = await axios.get("/account/products");
        const products: any = response.data;
        this.products = products;
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.productLoading = false;
      }
    },

    async fetchCustomerInfo(): Promise<void> {
      try {
        this.contactInfoLoading = true;
        const response = await axios.get("/account/customer");
        const contactInfo: any = response.data.contact;
        const paymentMethod: any = response.data.paymentMethod;
        this.contactInfo = contactInfo;
        this.paymentMethod = paymentMethod;
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.contactInfoLoading = false;
      }
    },

    async getAccountUsage(): Promise<any> {
      try {
        this.accountUsageLoading = true;
        const response = await axios.get("/account/usage");
        return response.data;
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.accountUsageLoading = false;
      }
    },

    async fetchInvoiceHistory(): Promise<void> {
      try {
        this.invoiceLoading = true;
        const response = await axios.get("/account/invoiceHistory");
        const paidInvoices: any = response.data.paid?.data;
        const upcomingInvoices: any = response.data.upcoming
          ? [response.data.upcoming]
          : [];
        this.paidInvoices = paidInvoices;
        this.upcomingInvoices = upcomingInvoices;
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.invoiceLoading = false;
      }
    },

    async createAccountLink(
      returnURL: string,
      refreshURL?: string
    ): Promise<string> {
      this.linkLoading = true;
      const link = await axios
        .post("/account/link", {
          type: "account_onboarding",
          refresh_url: refreshURL ? refreshURL : returnURL,
          return_url: returnURL,
          collection_options: {
            fields: "eventually_due",
            future_requirements: "include",
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((error: AxiosResponse) => {
          if (error.data.code !== "parameter_invalid_empty") {
            useSnackBarStore().toastError({ message: error.data });
          }
        })
        .finally(() => (this.linkLoading = false));

      return link;
    },

    async createCustomerPortalLink(): Promise<any> {
      this.customerPortalLinkLoading = true;
      await this.fetchPlanInfo();
      const link = await axios
        .post("/account/portal/update", {})
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((error: AxiosResponse) => {
          useSnackBarStore().toastError({ message: error.data });
        })
        .finally(() => (this.customerPortalLinkLoading = false));

      return link;
    },

    async createPortalCancelLink(): Promise<any> {
      this.subscriptionCancelLinkLoading = true;
      await this.fetchPlanInfo();
      const link = await axios
        .post("/account/portal/update", {
          flow_data: {
            type: "subscription_cancel",
            subscription_cancel: {
              subscription: this.subscription.id,
            },
            after_completion: {
              type: "redirect",
              redirect: {
                return_url: config?.links?.portalReturn,
              },
            },
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((error: AxiosResponse) => {
          useSnackBarStore().toastError({ message: error.data });
        })
        .finally(() => (this.subscriptionCancelLinkLoading = false));

      return link;
    },

    async createPortalUpdateLink(): Promise<any> {
      this.subscriptionUpdateLinkLoading = true;
      await this.fetchPlanInfo();
      const link = await axios
        .post("/account/portal/update", {
          flow_data: {
            type: "subscription_update",
            subscription_update: {
              subscription: this.subscription.id,
            },
            after_completion: {
              type: "redirect",
              redirect: {
                return_url: config?.links?.portalReturn,
              },
            },
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((error: AxiosResponse) => {
          useSnackBarStore().toastError({ message: error.data });
        })
        .finally(() => (this.subscriptionUpdateLinkLoading = false));

      return link;
    },

    async createCheckoutSessionLink(): Promise<any> {
      this.checkoutSessionLinkLoading = true;
      const link = await axios
        .post("/account/checkoutSession", {
          success_url: config?.links?.portalReturn,
          payment_method_types: ["card"],
          mode: "subscription",
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((error: AxiosResponse) => {
          useSnackBarStore().toastError({ message: error.data });
        })
        .finally(() => (this.checkoutSessionLinkLoading = false));

      return link;
    },

    async completeOnboarding() {
      this.completeOnboardingLinkLoading = true;
      await axios
        .post("/account/completeOnboarding", {})
        .then(() => {
          useAuthStore().accountStatus = NectarStatus.ACTIVE;
          if (this.account) {
            this.setCurrentAccount({
              ...this.account,
              nectarStatus: NectarStatus.ACTIVE,
            });
          }
        })

        .catch((error: AxiosResponse) => {
          useSnackBarStore().toastError({ message: error.data });
        })
        .finally(() => (this.completeOnboardingLinkLoading = false));
    },

    // unused but might need in future(?)
    async getPlanName() {
      try {
        this.planLoading = true;
        const response = await axios.get("/account/plan");

        if (response.data.plan.name) {
          return response.data.plan.name;
        } else {
          return AccountPlan.STARTER;
        }
      } catch (error: any) {
        useSnackBarStore().toastError({ message: error.data.error });
      } finally {
        this.planLoading = false;
      }
    },
  },
});
