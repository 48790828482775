export const getInitials = (name: string) => {
  const parts = name.split(" ") ?? [];
  let initials;

  if (parts.length > 1) {
    const first = (parts.shift() ?? "").charAt(0);
    const last = (parts.pop() ?? "").charAt(0);
    initials = `${first}${last}`.trim();
  } else {
    initials = name.substring(0, 2);
  }

  return initials.toUpperCase();
};
