import {
  SnackBarState,
  ToastErrorPayload,
  ToastSuccessPayload,
} from "@/types/global";
import { defineStore } from "pinia";

export const useSnackBarStore = defineStore("snackbar", {
  state: (): SnackBarState => ({
    isVisible: false,
    color: "info",
    timer: 5000,
    message: "",
    icon: "mdi-info",
  }),
  getters: {
    getVisibility(): boolean {
      return this.isVisible;
    },
    getColor(): string {
      return this.color;
    },
    getTimer(): number {
      return this.timer;
    },
    getMessage(): string {
      return this.message;
    },
    getIcon(): string {
      return this.icon;
    },
  },
  actions: {
    toggleVisibility(): void {
      this.isVisible = !this.isVisible;
    },
    toastSuccess(payload: ToastSuccessPayload): void {
      const { message } = payload;
      this.message = message;
      this.color = "success";
      this.icon = "mdi-check";
      this.isVisible = true;
    },
    toastError(payload: ToastErrorPayload): void {
      const { message, code, error } = payload;
      this.message = message || error || code || "error";
      this.color = "error";
      this.icon = "mdi-alert-circle-outline";
      this.isVisible = true;
    },
  },
});
