import { createI18n } from "vue-i18n";
import enUS from "@/locale/en-US";
import frCA from "@/locale/fr-CA";
import frQC from "@/locale/fr-QC";

const messages = {
  "en-US": { ...enUS },
  "fr-CA": { ...frCA },
  "fr-QC": { ...frQC },
};

const options = {
  locale: "en-US", // set locale
  messages: messages, // set locale messages
  silentFallbackWarn: true,
  globalInjection: true,
};

// Create VueI18n instance with options
export default createI18n(options);
