<template>
  <v-app>
    <div v-if="showLoading" style="background: #fffcd9">
      <v-row justify="center" class="mt-16">
        <v-col cols="10" md="6">
          <v-card-title
            class="mb-3 text-center"
            style="white-space: normal; word-wrap: break-word; padding: 10px"
            >{{ loadingMsg }}</v-card-title
          >
        </v-col>
      </v-row>
      <loading-animation />
    </div>
    <div v-if="!showLoading">
      <navigation-drawer v-if="useAuthStore().isLoggedIn" />
      <app-bar v-if="useAuthStore().isLoggedIn" />
      <default-view />
    </div>
  </v-app>
  <snack-bar />
  <notification-snack-bar />
</template>

<script lang="ts" setup>
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import AppBar from "@/components/AppBar.vue";
import DefaultView from "@/layouts/default/View.vue";
import SnackBar from "@/components/SnackBar.vue";
import { useAuthStore } from "./stores/auth";
import { computed, watch, onUnmounted } from "vue";
import i18n from "@/plugins/i18n";
import LoadingAnimation from "@/components/Common/LoadingAnimation.vue";
import { usePaymentLinkNotificationStore } from "@/stores/paymentLinkNotification";
import { useAccountStore } from "@/stores/account";
import NotificationSnackBar from "@/components/NotificationSnackBar.vue";
const paymentLinkNotificationStore = usePaymentLinkNotificationStore();
const accountId = computed(() => useAccountStore()?.account?.id);

watch(
  [() => useAuthStore().loading, accountId],
  ([loading, acctId]) => {
    if (!loading && acctId) {
      paymentLinkNotificationStore.listenToPaymentUpdates(acctId);
    } else {
      paymentLinkNotificationStore.stopListening();
    }
  },
  { immediate: true }
);

// Cleanup: Stop listening when the component unmounts
onUnmounted(() => {
  paymentLinkNotificationStore.stopListening();
});
const loadingMsg = computed(() => {
  const MAX_DEFINED_LANGUAGE_STRINGS = 15;
  const randomNum =
    Math.floor(Math.random() * MAX_DEFINED_LANGUAGE_STRINGS) + 1; // Random number between 1 and max
  const loginMessageKey = `loginLoading${randomNum}`;

  const loginMsg = useAuthStore().loading ? loginMessageKey : null;
  const changeSessionMsg = useAuthStore().changeSessionLoading
    ? "changeSessionLoading"
    : null;
  const logoutMsg = useAuthStore().logoutLoading ? "logoutLoading" : null;
  return i18n.global.t(`platform.${loginMsg || changeSessionMsg || logoutMsg}`);
});

const showLoading = computed(() => {
  return (
    useAuthStore().loading ||
    useAuthStore().changeSessionLoading ||
    useAuthStore().logoutLoading
  );
});
</script>
