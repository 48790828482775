import router from "@/router";
import { useAuthStore } from "@/stores/auth";

export const hasRoleAccessToRoute = (
  pathName: string,
  params = {},
  query = {}
) => {
  const to = router.resolve({
    name: pathName,
    params,
    query,
  });
  const accessRoles = to.matched.reduce((acc: any, r: any) => {
    const routeRoles = Array.isArray(r.meta.accessRoles)
      ? r.meta.accessRoles
      : [];

    return [...acc, ...routeRoles];
  }, []);
  const role = useAuthStore().getRoleName;

  if (Array.isArray(accessRoles) && accessRoles.length > 0) {
    const hasRole = accessRoles.includes(role);
    return hasRole;
  }
  return true;
};

export const hasRoleAccessToAction = (requiredRoles: string[]): boolean => {
  const userRole = useAuthStore().getRoleName;
  return requiredRoles.includes(userRole);
};
