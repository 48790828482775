import { hasRoleAccessToRoute } from "@/utils/role.utils";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  if (hasRoleAccessToRoute(to.name as string, to.params, to.query)) {
    next();
  } else {
    next({ name: "dashboard" });
  }
};
